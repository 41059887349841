import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useNavigate
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRouteLayout from "./layout/privateRouteLayout";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./components/Spinner";
import Cookies from "js-cookie";
import { isValidUser } from "./utils/common.js";
import { getAPI, PANEL_TYPE, SUPER_ADMIN_URL } from "./utils/config.js";
import jwt_decode from "jwt-decode";

const AddTravelagency = lazy(() => import("./pages/user/travel_agency/Add"));
const EditTravelagency = lazy(() => import("./pages/user/travel_agency/Edit"));
const Daf = lazy(() => import("./pages/user/daf/View"));
const Adddaf = lazy(() => import("./pages/user/daf/Add"));
const Editdaf = lazy(() => import("./pages/user/daf/Edit"));
const AreaManager = lazy(() => import("./pages/user/area_mamnger/View"));
const AddAreaManager = lazy(() => import("./pages/user/area_mamnger/Add"));
const EditAreaManager = lazy(() => import("./pages/user/area_mamnger/Edit"));
const Gaca = lazy(() => import("./pages/user/gaca/View"));
const Addgaca = lazy(() => import("./pages/user/gaca/Add"));
const Editgaca = lazy(() => import("./pages/user/gaca/Edit"));
const DocumentType = lazy(() => import("./pages/master/document_type/View"));
const AddDocumentType = lazy(() => import("./pages/master/document_type/Add"));
const EditDocumentType = lazy(() =>
  import("./pages/master/document_type/Edit")
);
const TripType = lazy(() => import("./pages/master/trip_type/View"));
const AddTripType = lazy(() => import("./pages/master/trip_type/Add"));
const EditTripType = lazy(() => import("./pages/master/trip_type/Edit"));
const Roles = lazy(() => import("./pages/roles/View"));
const AddRoles = lazy(() => import("./pages/roles/Add"));
const EditRoles = lazy(() => import("./pages/roles/Edit"));
const AllMenu = lazy(() => import("./pages/allMenu/Allmenu"));
const Tickets = lazy(() => import("./pages/master/tickets/View"));
const AddTickets = lazy(() => import("./pages/master/tickets/Add"));
const EditTickets = lazy(() => import("./pages/master/tickets/Edit"));
const HotelCategory = lazy(() => import("./pages/master/hotel_category/View"));
const HotelCategoryAdd = lazy(() => import("./pages/master/hotel_category/Add"));
const EditHotelCategory = lazy(() => import("./pages/master/hotel_category/Edit"));
const TransferCategory = lazy(() => import("./pages/master/transfer_category/View"));
const AddTransferCategory = lazy(() => import("./pages/master/transfer_category/Add"));
const EditTransferCategory = lazy(() => import("./pages/master/transfer_category/Edit"));
const TripReason = lazy(() => import("./pages/master/trip_reason/View"));
const AddTripReason = lazy(() => import("./pages/master/trip_reason/Add"));
const EditTripReason = lazy(() => import("./pages/master/trip_reason/Edit"));
const CancellationReason = lazy(() =>
  import("./pages/master/cancellation_reason/View")
);
const AddCancellationReason = lazy(() =>
  import("./pages/master/cancellation_reason/Add")
);
const EditCancellationReason = lazy(() =>
  import("./pages/master/cancellation_reason/Edit")
);
const ServiceType = lazy(() => import("./pages/master/service_type/View"));
const AddServiceType = lazy(() => import("./pages/master/service_type/Add"));
const EditServiceType = lazy(() => import("./pages/master/service_type/Edit"));
const Department = lazy(() => import("./pages/master/department/View"));
const AddDepartment = lazy(() => import("./pages/master/department/Add"));
const EditDepartment = lazy(() => import("./pages/master/department/Edit"));
const Company = lazy(() => import("./pages/master/company/View"));
const AddCompany = lazy(() => import("./pages/master/company/Add"));
const EditCompany = lazy(() => import("./pages/master/company/Edit"));
const TransferType = lazy(() => import("./pages/master/transfer_type/View"));
const AddTransferType = lazy(() => import("./pages/master/transfer_type/Add"));
const EditTransferType = lazy(() =>
  import("./pages/master/transfer_type/Edit")
);
const AgentOccupation = lazy(() =>
  import("./pages/master/agent_occupation/View")
);
const AddAgentOccupation = lazy(() =>
  import("./pages/master/agent_occupation/Add")
);
const EditAgentOccupation = lazy(() =>
  import("./pages/master/agent_occupation/Edit")
);
const Benefit = lazy(() => import("./pages/master/benefit/View"));
const AddBenefit = lazy(() => import("./pages/master/benefit/Add"));
const EditBenefit = lazy(() => import("./pages/master/benefit/Edit"));
const Agents = lazy(() => import("./pages/agents/View"));
const AddAgents = lazy(() => import("./pages/agents/Add"));
const EditAgents = lazy(() => import("./pages/agents/Edit"));
const Rules = lazy(() => import("./pages/master/rules/View"));
const AddRules = lazy(() => import("./pages/master/rules/Add"));
const EditRules = lazy(() => import("./pages/master/rules/Edit"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const SignIn = lazy(() => import("./pages/signin/Signin"));
const Users = lazy(() => import("./pages/user/super_admin/View"));
const AddUsers = lazy(() => import("./pages/user/super_admin/Add"));
const UserProfile = lazy(() => import("./pages/profile/user_profile"));
const EditUsers = lazy(() => import("./pages/user/super_admin/Edit"));
const Travelagency = lazy(() => import("./pages/user/travel_agency/View"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/resetPassword/ResetPassword"));
const TravelAgencyCompany = lazy(() => import("./pages/master/travelAgencyCompany/View"));
const AddTravelAgencyCompany = lazy(() => import("./pages/master/travelAgencyCompany/Add"));
const EditTravelAgencyCompany = lazy(() => import("./pages/master/travelAgencyCompany/Edit"));
const Country = lazy(() => import("./pages/master/country/View"));
const AddCountry = lazy(() => import("./pages/master/country/Add"));
const EditCountry = lazy(() => import("./pages/master/country/Edit"));
const VisaReqirements = lazy(() => import("./pages/master/visa_requirements/View"));
const AddVisaReqirements = lazy(() => import("./pages/master/visa_requirements/Add"));
const EditVisaReqirements = lazy(() => import("./pages/master/visa_requirements/Edit"));
const Arrivals = lazy(() => import("./pages/master/arrivals/View"));
const AddArrivals = lazy(() => import("./pages/master/arrivals/Add"));
const EditArrivals = lazy(() => import("./pages/master/arrivals/Edit"));
const Departure = lazy(() => import("./pages/master/departure/View"));
const AddDeparture = lazy(() => import("./pages/master/departure/Add"));
const EditDeparture = lazy(() => import("./pages/master/departure/Edit"));
/**
 * @author : Kishan Sonani.
 * @purpose : 
 * @param : 
 * @since : 21-Apr-2023.
 */

const RentCarCategory = lazy(() => import("./pages/master/rent_car_category/View"));
const AddRentCarCategory = lazy(() => import("./pages/master/rent_car_category/Add"));
const EditRentCarCategory = lazy(() => import("./pages/master/rent_car_category/Edit"));

const Currency = lazy(() => import("./pages/master/currency/View"));
const AddCurrency = lazy(() => import("./pages/master/currency/Add"));
const EditCurrency = lazy(() => import("./pages/master/currency/Edit"));

const Answer = lazy(() => import("./pages/master/answer/View"));
const AddAnswer = lazy(() => import("./pages/master/answer/Add"));
const EditAnswer = lazy(() => import("./pages/master/answer/Edit"));

const Question = lazy(() => import("./pages/master/question/View"));
const AddQuestion = lazy(() => import("./pages/master/question/Add"));
const EditQuestion = lazy(() => import("./pages/master/question/Edit"));

const EmailTemplates = lazy(() => import("./pages/master/email_templates/View"));
const AddEmailTemplates = lazy(() => import("./pages/master/email_templates/Add"));
const EditEmailTemplates = lazy(() => import("./pages/master/email_templates/Edit"));


function App() {
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  useEffect(() => {
    // console.log("token", Cookies.get("G-accessToken"))
    /* if (!Cookies.get("G-accessToken")) {
      window.location.replace(SUPER_ADMIN_URL);
      return;
    } */
    const getDetails = async () => {
      try {
        const decodedToken = jwt_decode(Cookies.get("G-accessToken"));
        if (decodedToken?.ID) {
          const detailInfo = await getAPI({
            url: `user/detail`,
            params: { ID: decodedToken?.ID },
          });
          if (!isValidUser(detailInfo)) {
            window.location.href = SUPER_ADMIN_URL
          }
          if (detailInfo?.data?.payload) {
            if (detailInfo?.data?.payload?.role?.UID !== PANEL_TYPE) {
              // window.location.href = SUPER_ADMIN_URL
            }
            setInfo({
              ...detailInfo?.data?.payload,
              cpassword: detailInfo?.data?.payload?.password,
            });
          } else {
          }
        }
      } catch (error) {

        /* window.location.replace(SUPER_ADMIN_URL) */
        // return
      }
    };
    getDetails();
  }, [navigate]);
  return (
    <main>
      <div className="bg-skin-gray h-screen">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          theme="colored"
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route exact path="*" element={<SignIn />} />
            <Route exact path="/" element={<SignIn />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route
              element={
                <PrivateRouteLayout userInfo={info}>
                  <Outlet />
                </PrivateRouteLayout>
              }
            >
              <Route
                exact
                path="/dashboard"
                element={<Dashboard />}
              />
              <Route
                exact
                path="/user/super_admin"
                element={<Users />}
              />
              <Route
                exact
                path="/user/super_admin/add"
                element={<AddUsers />}
              />
              <Route
                exact
                path="/user/super_admin/edit"
                element={<EditUsers />}
              />
              <Route
                exact
                path="/user/travel_agency"
                element={<Travelagency />}
              />
              <Route
                exact
                path="/user/travel_agency/add"
                element={<AddTravelagency />}
              />
              <Route
                exact
                path="/user/travel_agency/edit"
                element={<EditTravelagency />}
              />
              <Route
                exact
                path="/user/gaca"
                element={<Gaca />}
              />
              <Route
                exact
                path="/user/gaca/add"
                element={<Addgaca />}
              />
              <Route
                exact
                path="/user/gaca/edit"
                element={<Editgaca />}
              />
              <Route exact path="/user/daf" element={<Daf />} />
              <Route
                exact
                path="/user/daf/add"
                element={<Adddaf />}
              />
              <Route
                exact
                path="/user/daf/edit"
                element={<Editdaf />}
              />
              <Route
                exact
                path="/user/area_mamnger"
                element={<AreaManager />}
              />
              <Route
                exact
                path="/user/area_mamnger/add"
                element={<AddAreaManager />}
              />
              <Route
                exact
                path="/user/area_mamnger/edit"
                element={<EditAreaManager />}
              />
              <Route
                exact
                path="/master/document_type"
                element={<DocumentType />}
              />
              <Route
                exact
                path="/master/document_type/add"
                element={<AddDocumentType />}
              />
              <Route
                exact
                path="/master/document_type/edit"
                element={<EditDocumentType />}
              />

              <Route exact path="/roles" element={<Roles />} />
              <Route
                exact
                path="/roles/add"
                element={<AddRoles />}
              />
              <Route
                exact
                path="/roles/edit"
                element={<EditRoles />}
              />
              <Route
                exact
                path="/allMenu"
                element={<AllMenu />}
              />
              <Route
                exact
                path="/master/tickets"
                element={<Tickets />}
              />
              <Route
                exact
                path="/master/tickets/add"
                element={<AddTickets />}
              />
              <Route
                exact
                path="/master/tickets/edit"
                element={<EditTickets />}
              />
              <Route
                exact
                path="/master/hotel_category"
                element={<HotelCategory />}
              />
              <Route
                exact
                path="/master/hotel_category/add"
                element={<HotelCategoryAdd />}
              />
              <Route
                exact
                path="/master/hotel_category/edit"
                element={<EditHotelCategory />}
              />
              <Route
                exact
                path="/master/transfer_category"
                element={<TransferCategory />}
              />
              <Route
                exact
                path="/master/transfer_category/add"
                element={<AddTransferCategory />}
              />
              <Route
                exact
                path="/master/transfer_category/edit"
                element={<EditTransferCategory />}
              />
              <Route
                exact
                path="/master/trip_reason"
                element={<TripReason />}
              />
              <Route
                exact
                path="/master/trip_reason/add"
                element={<AddTripReason />}
              />
              <Route
                exact
                path="/master/trip_reason/edit"
                element={<EditTripReason />}
              />
              <Route
                exact
                path="/master/cancellation_reason"
                element={<CancellationReason />}
              />
              <Route
                exact
                path="/master/cancellation_reason/add"
                element={<AddCancellationReason />}
              />
              <Route
                exact
                path="/master/cancellation_reason/edit"
                element={<EditCancellationReason />}
              />
              <Route
                exact
                path="/master/service_type"
                element={<ServiceType />}
              />
              <Route
                exact
                path="/master/service_type/add"
                element={<AddServiceType />}
              />
              <Route
                exact
                path="/master/service_type/edit"
                element={<EditServiceType />}
              />
              <Route
                exact
                path="/master/department"
                element={<Department />}
              />
              <Route
                exact
                path="/master/department/add"
                element={<AddDepartment />}
              />
              <Route
                exact
                path="/master/department/edit"
                element={<EditDepartment />}
              />
              <Route
                exact
                path="/master/company"
                element={<Company />}
              />
              <Route
                exact
                path="/master/company/add"
                element={<AddCompany />}
              />
              <Route
                exact
                path="/master/company/edit"
                element={<EditCompany />}
              />
              <Route
                exact
                path="/master/transfer_type"
                element={<TransferType />}
              />
              <Route
                exact
                path="/master/transfer_type/add"
                element={<AddTransferType />}
              />
              <Route
                exact
                path="/master/transfer_type/edit"
                element={<EditTransferType />}
              />
              <Route
                exact
                path="/master/agent_occupation"
                element={<AgentOccupation />}
              />
              <Route
                exact
                path="/master/agent_occupation/add"
                element={<AddAgentOccupation />}
              />
              <Route
                exact
                path="/master/agent_occupation/edit"
                element={<EditAgentOccupation />}
              />
              <Route
                exact
                path="/master/trip_type"
                element={<TripType />}
              />
              <Route
                exact
                path="/master/trip_type/add"
                element={<AddTripType />}
              />
              <Route
                exact
                path="/master/trip_type/edit"
                element={<EditTripType />}
              />
              <Route
                exact
                path="/master/benefit"
                element={<Benefit />}
              />
              <Route
                exact
                path="/master/benefit/add"
                element={<AddBenefit />}
              />
              <Route
                exact
                path="/master/benefit/edit"
                element={<EditBenefit />}
              />
              <Route
                exact
                path="/master/rules"
                element={<Rules />}
              />
              <Route
                exact
                path="/master/rules/add"
                element={<AddRules />}
              />
              <Route
                exact
                path="/master/rules/edit"
                element={<EditRules />}
              />
              <Route
                exact
                path="/agents"
                element={<Agents />}
              />
              <Route
                exact
                path="/agents/add"
                element={<AddAgents />}
              />
              <Route
                exact
                path="/agents/edit"
                element={<EditAgents />}
              />
              <Route
                exact
                path="/profile/user_profile"
                element={<UserProfile userInfo={info} />}
              />

              <Route
                exact
                path="/master/travelAgencyCompany"
                element={<TravelAgencyCompany />}
              />
              <Route
                exact
                path="/master/travelAgencyCompany/add"
                element={<AddTravelAgencyCompany />}
              />
              <Route
                exact
                path="/master/travelAgencyCompany/edit"
                element={<EditTravelAgencyCompany />}
              />
              <Route
                exact
                path="/master/country"
                element={<Country />}
              />
              <Route
                exact
                path="/master/country/add"
                element={<AddCountry />}
              />
              <Route
                exact
                path="/master/country/edit"
                element={<EditCountry />}
              />
              <Route
                exact
                path="/master/visa_requirements"
                element={<VisaReqirements />}
              />
              <Route
                exact
                path="/master/visa_requirements/add"
                element={<AddVisaReqirements />}
              />
              <Route
                exact
                path="/master/visa_requirements/edit"
                element={<EditVisaReqirements />}
              />
              <Route
                exact
                path="/master/arrivals"
                element={<Arrivals />}
              />
              <Route
                exact
                path="/master/arrivals/add"
                element={<AddArrivals />}
              />
              <Route
                exact
                path="/master/arrivals/edit"
                element={<EditArrivals />}
              />

              <Route
                exact
                path="/master/departure"
                element={<Departure />}
              />
              <Route
                exact
                path="/master/departure/add"
                element={<AddDeparture />}
              />
              <Route
                exact
                path="/master/departure/edit"
                element={<EditDeparture />}
              />

              <Route
                exact
                path="/master/rent-car-category"
                element={<RentCarCategory />}
              />
              <Route
                exact
                path="/master/rent-car-category/add"
                element={<AddRentCarCategory />}
              />
              <Route
                exact
                path="/master/rent-car-category/edit"
                element={<EditRentCarCategory />}
              />
              <Route
                exact
                path="/master/currency"
                element={<Currency />}
              />
              <Route
                exact
                path="/master/currency/add"
                element={<AddCurrency />}
              />
              <Route
                exact
                path="/master/currency/edit"
                element={<EditCurrency />}
              />

              <Route
                exact
                path="/master/answer"
                element={<Answer />}
              />
              <Route
                exact
                path="/master/answer/add"
                element={<AddAnswer />}
              />
              <Route
                exact
                path="/master/answer/edit"
                element={<EditAnswer />}
              />
              <Route
              exact
              path="/master/question"
              element={<Question />}
            />
            <Route
              exact
              path="/master/question/add"
              element={<AddQuestion />}
            />
            <Route
              exact
              path="/master/question/edit"
              element={<EditQuestion />}
              />
              <Route
                exact
                path="/master/email-templates"
                element={<EmailTemplates />}
              />
              <Route
                exact
                path="/master/email-templates/add"
                element={<AddEmailTemplates />}
              />
              <Route
                exact
                path="/master/email-templates/edit"
                element={<EditEmailTemplates />}
              />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </main>
  );
}
export default App;
