import axios from "axios";
import Cookies from "js-cookie";

const getAPIBaseURl = () => {
  return process.env.REACT_APP_BASE_API_URL;
};

const getReqHeaders = () => {
  const accessToken = Cookies.get("G-accessToken");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export const STATIC_CONTENT = process.env.REACT_APP_STATIC_PATH_URL;
export const STATIC_CONTENT_AGENT_DOC = process.env.REACT_APP_STATIC_AGENT_DOC_PATH_URL;
export const STATIC_CONTENT_CONTRACT_DOCUMENT = process.env.REACT_APP_CONTRACT_DOCUMENT_STATIC_PATH_URL;
export const PANEL_TYPE = process.env.REACT_APP_PANEL_TYPE;
export const SUPER_ADMIN_URL = process.env.REACT_APP_SUPER_ADMIN_LINK;
export const TRAVEL_AGENCY_URL = process.env.REACT_APP_TRAVEL_AGENCY_LINK;
export const AREA_MANAGER_URL = process.env.REACT_APP_AREA_MANAGER_LINK;
export const GACA_URL = process.env.REACT_APP_GACA_LINK;
export const DAF_URL = process.env.REACT_APP_DAF_LINK;
export const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN;

export const DOC_TYPE_PASSAPORTE_NORMAL_ID = process.env.REACT_APP_DOC_TYPE_PASSAPORTE_NORMAL_ID;
export const DOC_TYPE_PASSAPORTE_SERVICE_ID = process.env.REACT_APP_DOC_TYPE_PASSAPORTE_SERVICE_ID;
export const DOC_TYPE_IDENTITY_CARD = process.env.REACT_APP_DOC_TYPE_IDENTITY_CARD_ID;

export const postAPI = async (data) => {
  try {
    return await axios.post(`${getAPIBaseURl()}${data.url}`, data.params, {
      headers: getReqHeaders(),
    });
  } catch (error) {
    return error;
  }
};

export const deleteAPI = async (data) => {
  try {
    return await axios.delete(`${getAPIBaseURl()}${data.url}`, {
      headers: getReqHeaders(),
      data: data.params,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("G-accessToken");
    }
    return error;
  }
};

export const patchAPI = async (data) => {
  try {
    return await axios({
      method: "patch",
      url: `${getAPIBaseURl()}${data.url}`,
      data: data.params,
      headers: getReqHeaders(),
    });
    // return await axios.patch(`${getAPIBaseURl()}${data.url}`, data.params, {
    //   headers: getReqHeaders(),
    // });
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("G-accessToken");
    }
    return error;
  }
};

export const getAPI = async (data) => {
  try {
    return await axios.get(`${getAPIBaseURl()}${data.url}`, {
      params: data.params,
      headers: getReqHeaders(),
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("G-accessToken");
    }
    return error;
  }
};

export const putAPI = async (data) => {
  try {
    return await axios.put(`${getAPIBaseURl()}${data.url}`, data.params, {
      headers: getReqHeaders(),
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("G-accessToken");
    }
    return error;
  }
};
