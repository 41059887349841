import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header/Header";
import LeftNavigation from "../components/LeftNavigation/leftNavigation";
import { Dialog, Transition } from "@headlessui/react";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getAPI, PANEL_TYPE } from "../utils/config";
import { isValidUser } from "../utils/common";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

// For Login User Layout
function PrivateRouteLayout({ children }) {
  const { pathname } = useLocation();
  const [info, setInfo] = useState({});
  const [roleInfo, setRoleInfo] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      try {
        if (!Cookies.get("G-accessToken")) {
          navigate("/");
          return
        }
        const decodedToken = jwt_decode(Cookies.get("G-accessToken"))
        if (decodedToken?.ID) {
          const response = await getAPI({ url: "roles/list" });
          if (!isValidUser(response)) {
            Cookies.remove("G-accessToken")
            navigate("/");
            return toast.error("Sua sessão expirou")
          }
          if (response?.data?.payload) {
            setRoleInfo(response?.data?.payload?.rows);
          }
          const detailInfo = await getAPI({
            url: `user/detail`,
            params: { ID: decodedToken?.ID },
          });
          if (detailInfo?.data?.payload) {
            if (detailInfo?.data?.payload?.role?.UID !== PANEL_TYPE) {
              navigate('/')
              Cookies.remove("G-accessToken")
              return toast.error("Acesso ao painel inválido")
            }
            setInfo({
              ...detailInfo?.data?.payload,
              cpassword: detailInfo?.data?.payload?.password,
            });
          } else {
            navigate('/')
            Cookies.remove("G-accessToken")
            return toast.error("Usuário Inválido")
          }
        }
      } catch (error) {
        navigate('/')
        Cookies.remove("G-accessToken")
        return toast.error("Sua sessão expirou")
      }
    };
    getDetails();
  }, [navigate, pathname]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigation = [
    { name: "Home", href: "#", icon: HomeIcon, current: true },
    { name: "History", href: "#", icon: ClockIcon, current: false },
    { name: "Balances", href: "#", icon: ScaleIcon, current: false },
    { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
    { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
    { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
  ];
  const secondaryNavigation = [
    { name: "Settings", href: "#", icon: CogIcon },
    { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
    { name: "Privacy", href: "#", icon: ShieldCheckIcon },
  ];
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="min-h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                    alt="Easywire logo"
                  />
                </div>
                <nav
                  className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-skin-primary text-white"
                            : "text-white hover:text-white left-menu-btn",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="mt-6 pt-6">
                    <div className="space-y-1 px-2">
                      {secondaryNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {!!roleInfo.length && <LeftNavigation roleInfo={roleInfo} />}
      </div>
      <div className="flex flex-1 flex-col lg:pl-64">
        <Header userInfo={info} />
        {children}
      </div>
    </div>
  );
}

export default PrivateRouteLayout;
