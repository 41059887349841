import Cookies from "js-cookie";

export const validateForm = (formBody, requiredField) => {
  const fields = { ...formBody };
  let errors = {};
  let formIsValid = true;

  if (requiredField.includes("documentTypeID")) {
    if (!fields["documentTypeID"]) {
      formIsValid = false;
      errors["documentTypeID"] = "Selecione o tipo de documento";
    }
  }
  // if (requiredField.includes("file")) {
  //   if (!fields["file"]) {
  //     formIsValid = false;
  //     errors["file"] = "Please add document file";
  //   }
  // }
  if (requiredField.includes("identificationNumber")) {
    if (!fields["identificationNumber"]) {
      formIsValid = false;
      errors["identificationNumber"] =
        "Por favor, insira o número do documento de identificação";
    }
  }
  if (requiredField.includes("issueDate")) {
    if (!fields["issueDate"]) {
      formIsValid = false;
      errors["issueDate"] = "Selecione a data de emissão";
    }
  }
  if (requiredField.includes("expireDate")) {
    if (!fields["expireDate"]) {
      formIsValid = false;
      errors["expireDate"] = "Selecione a data de expiração";
    }
  }
  if (requiredField.includes("cardCheck")) {
    if (!fields["cardCheck"]) {
      formIsValid = false;
      errors["cardCheck"] = "Por favor, selecione Cheque de Cartão Corporativo";
    }
  }
  if (requiredField.includes("SAPnumber")) {
    if (!fields["SAPnumber"]) {
      formIsValid = false;
      errors["SAPnumber"] = "Insira o número SAP";
    }
  }
  if (requiredField.includes("area")) {
    if (!fields["area"]) {
      formIsValid = false;
      errors["area"] = "Por favor, insira a área";
    }
  }
  if (requiredField.includes("title")) {
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = "Por favor insira o título";
    }
  }

  if (requiredField.includes("name")) {
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Por Favor, Insira O Moeda";
    }
  }

  if (requiredField.includes("ticketCategoryID")) {
    if (!fields["ticketCategoryID"]) {
      formIsValid = false;
      errors["ticketCategoryID"] = "Selecione a categoria do ingresso";
    }
  }
  if (requiredField.includes("ruleType")) {
    if (!fields["ruleType"]) {
      formIsValid = false;
      errors["ruleType"] = "Selecione o tipo de regra";
    }
  }
  if (requiredField.includes("ruleName")) {
    if (!fields["ruleName"]) {
      formIsValid = false;
      errors["ruleName"] = "Por favor insira o nome da regra";
    }
  }
  if (requiredField.includes("ruleNoOfDays")) {
    if (!fields["ruleNoOfDays"]) {
      formIsValid = false;
      errors["ruleNoOfDays"] = "Por favor insira o Viagens sem a necessidade de visto";
    }
  }
  if (requiredField.includes("ruleNoOfDaysWithVisa")) {
    if (!fields["ruleNoOfDaysWithVisa"]) {
      formIsValid = false;
      errors["ruleNoOfDaysWithVisa"] = "Por favor insira o Viagens com a necessidade de visto";
    }
  }
  if (requiredField.includes("hotelCategoryID")) {
    if (!fields["hotelCategoryID"]) {
      formIsValid = false;
      errors["hotelCategoryID"] = "Selecione a categoria do hotel";
    }
  }
  if (requiredField.includes("transferCategoryID")) {
    if (!fields["transferCategoryID"]) {
      formIsValid = false;
      errors["transferCategoryID"] = "Selecione a categoria de transferência";
    }
  }

  if (requiredField.includes("agentOccupation")) {
    if (!fields["agentOccupation"]) {
      formIsValid = false;
      errors["agentOccupation"] = "Selecione a ocupação do agente";
    }
  }
  if (requiredField.includes("departmentID")) {
    if (!fields["departmentID"]) {
      formIsValid = false;
      errors["departmentID"] = "Selecione o departamento";
    }
  }
  if (requiredField.includes("serviceType")) {
    if (!fields["serviceType"]) {
      formIsValid = false;
      errors["serviceType"] = "Selecione o tipo de serviço";
    }
  }
  if (requiredField.includes("roleID")) {
    if (!fields["roleID"]) {
      formIsValid = false;
      errors["roleID"] = "Selecione a função";
    }
  }

  if (requiredField.includes("email")) {
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Por Favor, Insira O Email";
    } else {
      const lastAtPos = fields["email"].lastIndexOf("@");
      const lastDotPos = fields["email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Por favor insira o endereço de e-mail válido";
      }
    }
  }

  if (requiredField.includes("mobile")) {
    var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    if (!pattern.test(fields["mobile"])) {
      formIsValid = false;
      errors["mobile"] = "Por favor insira apenas o número";
    }
    if (!fields["mobile"]) {
      formIsValid = false;
      errors["mobile"] = "Por favor, insira o número do celular";
    } else {
      if (fields["mobile"].length < 7 || fields["mobile"].length > 11) {
        formIsValid = false;
        errors["mobile"] = "O número do celular deve ter de 7 a 11 dígitos";
      }
    }
  }

  if (requiredField.includes("password")) {
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Por Favor, Insira O Senha";
    } else {
      if (fields["password"].length < 6) {
        formIsValid = false;
        errors["password"] = "A senha deve ter 6 caracteres";
      }
    }
  }

  if (requiredField.includes("cpassword")) {
    if (!fields["cpassword"]) {
      formIsValid = false;
      errors["cpassword"] = "Por favor, digite a senha de confirmação";
    }

    if (fields["password"] !== fields["cpassword"]) {
      formIsValid = false;
      errors["cpassword"] = "A senha e a senha de confirmação não correspondem";
    }
  }


  if (requiredField.includes("NIF")) {
    if (!fields["NIF"]) {
      formIsValid = false;
      errors["NIF"] = "Por favor insira NIF";
    }
  }

  if (requiredField.includes("address")) {
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Por favor, insira o endereço";
    }
  }

  if (requiredField.includes("status")) {
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = "Por Favor, Seleccione O Estado";
    }
  }
  if (requiredField.includes("symbol")) {
    if (!fields["symbol"]) {
      formIsValid = false;
      errors["symbol"] = "Por Favor, Insira O Símbolo";
    }
  }

  if (requiredField.includes("rent_car_category_id")) {
    if (!fields["rent_car_category_id"]) {
      formIsValid = false;
      errors["rent_car_category_id"] = "Selecione a Categoria de Rent-a-Car";
    }
  }

  if (requiredField.includes("contract_document_id")) {
    if (!fields["contract_document_id"]) {
      formIsValid = false;
      errors["contract_document_id"] = "Por Favor, Seleccione O Tipo de documento";
    }
  }
  if (requiredField.includes("expiration_date")) {
    if (!fields["expiration_date"]) {
      formIsValid = false;
      errors["expiration_date"] = "Por Favor, Seleccione O  Data de expiração";
    }
  }
  if (requiredField.includes("start_date")) {
    if (!fields["start_date"]) {
      formIsValid = false;
      errors["start_date"] = "Por Favor, Seleccione O Data de início";
    }
  }
  // if (requiredField.includes("file")) {
  //   if (!fields["file"]) {
  //     formIsValid = false;
  //     errors["file"] = "Por Favor, Seleccione O Documento";
  //   }
  // }
  if (requiredField.includes("currency_id")) {
    if (!fields["currency_id"]) {
      formIsValid = false;
      errors["currency_id"] = "Por Favor, Seleccione O Tipo de moeda";
    }
  }
  if (requiredField.includes("po_number")) {
    if (!fields["po_number"]) {
      formIsValid = false;
      errors["po_number"] = "Por Favor, Insira O Número da PO";
    }
  }
  if (requiredField.includes("contract_value")) {
    if (!fields["contract_value"]) {
      formIsValid = false;
      errors["contract_value"] = "Por Favor, Insira O Valor do contrato";
    }
  }
  if (requiredField.includes("contract_purpose")) {
    if (!fields["contract_purpose"]) {
      formIsValid = false;
      errors["contract_purpose"] = "Por Favor, Insira O Objecto do contrato";
    }
  }
  if (requiredField.includes("trip_type_id")) {
    if (!fields["trip_type_id"]) {
      formIsValid = false;
      errors["trip_type_id"] = "Por Favor, Seleccione O Tipo de viagem";
    }
  }
  if (requiredField.includes("document_type_id")) {
    if (!fields["document_type_id"]) {
      formIsValid = false;
      errors["document_type_id"] = "Por Favor, Seleccione O Tipo de documento";
    }
  }


  if (requiredField.includes("newPassword")) {
    if (!fields["newPassword"]) {
      formIsValid = false;
      errors["newPassword"] = "Por favor, digite a senha";
    }
  }
  if (requiredField.includes("confirmPassword")) {
    if (!fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = "Por favor, digite a senha de confirmação";
    }
  }

  if (requiredField.includes("answer")) {
    if (!fields["answer"]) {
      formIsValid = false;
      errors["answer"] = "Por Favor, Insira O Respostas";
    }
  }
  if (requiredField.includes("question")) {
    if (!fields["question"]) {
      formIsValid = false;
      errors["question"] = "Por Favor, Insira O Perguntas";
    }
  }
  if (requiredField.includes("question_type")) {
    if (!fields["question_type"]) {
      formIsValid = false;
      errors["question_type"] = "Por Favor, Insira O Perguntas Tipo";
    }
  }
  if (requiredField.includes("agent_occupation_id")) {
    if (!fields["agent_occupation_id"]) {
      formIsValid = false;
      errors["agent_occupation_id"] = "Por Favor, Seleccione O Função";
    }
  }
  if (requiredField.includes("travel_agency_company")) {
    if (!fields["travel_agency_company"]) {
      formIsValid = false;
      errors["travel_agency_company"] = "Por Favor, Seleccione O Agencia de viagens";
    }
  }
  if (requiredField.includes("code")) {
    if (!fields["code"]) {
      formIsValid = false;
      errors["code"] = "Por Favor, Insira O Código";
    }
  }
  if (requiredField.includes("email_subject")) {
    if (!fields["email_subject"]) {
      formIsValid = false;
      errors["email_subject"] = "Por Favor, Insira O Assunto";
    }
  }
  if (requiredField.includes("from_email")) {
    if (!fields["from_email"]) {
      formIsValid = false;
      errors["from_email"] = "Por Favor, Insira O Do email";
    }
  }
  if (requiredField.includes("from_name")) {
    if (!fields["from_name"]) {
      formIsValid = false;
      errors["from_name"] = "Por Favor, Insira O De nome";
    }
  }

  return {
    isValid: formIsValid,
    errors,
  };
};

export const isValidUser = (request) => {
  if (request?.response?.status === 401 || request?.response?.status === 500) {
    Cookies.remove("G-accessToken");
    return false;
  }
  return true;
};
