import { Disclosure } from "@headlessui/react";
import {
  SquaresPlusIcon,
  UserIcon,
  UserGroupIcon,
  AdjustmentsVerticalIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function LeftNavigation({ roleInfo }) {
  const [roleList, setRoleList] = useState([]);

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: SquaresPlusIcon,
      current: true,
    },
    { name: "Papeis", href: "/roles", icon: UserIcon, current: false },
    {
      name: "Utilizadores",
      icon: UserGroupIcon,
      current: false,
      children: roleList,
    },
    { name: "Colaboradores", href: "/agents", icon: UsersIcon },
    { name: "Outros Menus", href: "/allMenu", icon: AdjustmentsVerticalIcon },
  ];

  useEffect(() => {
    const staticRoute = {
      "Super Administrador": "super_admin",
      "Gestor de Área": "area_mamnger",
      "Agência de Viagens": "travel_agency",
      GACA: "gaca",
      DAF: "daf",
    };

    const displayData = roleInfo?.map((roleData) => {
      return {
        name: roleData?.title,
        href: `/user/${staticRoute?.[roleData?.title]}?roleID=${roleData.ID}`,
      };
    });
    setRoleList(displayData);
  }, [roleInfo]);

  return (
    <>
      <div className="flex flex-grow flex-col overflow-y-auto bg-skin-secondary pt-5 pb-4">
        <div className="flex flex-shrink-0 items-center px-4">
          <img
            className="h-8 w-auto"
            src="/img/logo-inner.png"
            alt="Your Company"
          />
        </div>
        <div className="mt-5 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1 px-2" aria-label="Sidebar">
            {navigation.map((item, index) =>
              !item.children ? (
                <div key={item.name + index}>
                  <NavLink
                    className={(navData) =>
                      navData?.isActive ? "activeNav" : ""
                    }
                    to={item.href}
                  >
                    <div
                      className={
                        "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md text-white hover:text-white left-menu-btn"
                      }
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-white"
                            : "text-white group-hover:text-white",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </div>
                  </NavLink>
                </div>
              ) : (
                <Disclosure
                  defaultOpen={true}
                  as="div"
                  key={item.name + index}
                  className="space-y-1"
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={classNames(
                          item.current
                            ? "bg-skin-secondary text-white"
                            : "text-white hover:bg-gray-50 hover:text-white left-menu-btn",
                          "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none"
                        )}
                      >
                        <item.icon
                          className="mr-3 h-6 w-6 flex-shrink-0 text-white group-hover:text-white"
                          aria-hidden="true"
                        />
                        <span className="flex-1">{item.name}</span>
                        <svg
                          className={classNames(
                            open ? "text-white rotate-90" : "text-white",
                            "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-white"
                          )}
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                      </Disclosure.Button>
                      <Disclosure.Panel className="space-y-1">
                        {item.children.map((subItem, index) => (
                          <NavLink
                            className={(navData) =>
                              navData.isActive ? "activeNav" : ""
                            }
                            key={subItem.name + index}
                            to={subItem.href}
                          >
                            <div
                              key={subItem.name}
                              className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-white text-sm font-medium text-white hover:text-white  left-menu-btn "
                            >
                              {subItem.name}
                            </div>
                          </NavLink>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )
            )}
          </nav>
        </div>
      </div>
    </>
  );
}

export default React.memo(LeftNavigation);
